import React from "react";
import { Link } from "gatsby";
import { Helmet } from 'react-helmet';
import favIcon from '../images/favicon.ico';

import "./normalize.css";
import "./layout.css";
export default function Layout({ title, children }) {
  return (
      <>
        <Helmet>
            <title>HJTS.nl | {title}</title>
            <meta charset="utf-8" />
            <meta name="description" content="" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="icon" href={favIcon} />
            <meta property="og:title" content="" />
            <meta property="og:type" content="" />
            <meta property="og:url" content="" />
            <meta property="og:image" content="" />

            <link rel="manifest" href="site.webmanifest" />
            <link rel="apple-touch-icon" href="icon.png" />
            <meta name="theme-color" content="#fafafa" />
        </Helmet>
        <div className="wrapper shadow col-7">
            <div className="navigation col-3">
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/stories">Korte verhalen</Link>
                    </li>
                    <li>
                        <Link to="/contact">Contact</Link>
                    </li>
                </ul>
            </div>
            <div className="content col-9">
                {children}
            </div>
        </div>
    </>
  );
}